<template>
  <div id="articleInfo">
    <!-- 导航1 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>系统公告</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容2 -->
    <div class="content">
      <!-- 搜索框sel2.1 -->
      <el-form inline label-position="right" label-width="60px" size="small" class="query-form">
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selClick()">查询</el-button>
          <el-button type="success" @click="dialogVisible=true;form={isEnable:true};">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表框2.2 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>公告列表</span>
        </div>
        <!-- 列表tab -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width:100%;"
          @sort-change="sortChange"
          @filter-change="filterTag"
        >
          <el-table-column prop="title" label="标题">
            <div slot-scope="scope" @click="titleClick(scope.row)">
              <span>{{scope.row.title}}</span>
            </div>
          </el-table-column>
          <el-table-column prop="viewCount" label="浏览量"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="createTime" label="创建日期"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页条page -->
        <div class="block" style="margin-top:20px;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50,100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
    <!-- 对话框 -->
    <el-dialog :title="form.id>0?'编辑公告':'添加公告'" :visible.sync="dialogVisible">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item
          label="公告标题"
          prop="title"
          :rules="[{required:true,message:'请输入标题，最多30个字'},{min:2,max:30,message:'不能超过30个字符'}]"
        >
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="sort">
          <el-input v-model.number="form.sort" placeholder="请输入排序号"></el-input>
        </el-form-item>

        <el-form-item label="公告内容" prop="content" :rules="[{required:true,message:'请输入内容'}]">
          <vue-ueditor-wrap v-model="form.content" :config="editorConfig"></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 公告查看对话框 -->
    <el-dialog :title="dia2.title" :visible.sync="dia2.vis">
      <el-card id="infoCard"></el-card>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "articleInfo",
  components: { VueUeditorWrap },
  data: () => {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      domain: url.getDomain(),
      dia2: { title: "", content: "", vis: false }, //对话框2
    };
  },
  methods: {
    handleSizeChange: function (val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange: function (val) {
      this.currentPage4 = val;
      this.getList();
    },
    // 排序
    sortChange: function (sortColumn) {
      this.query.sort = sortColumn.sort;
      this.query.order = sortColumn.order;
      this.getList();
    },
    // 筛选
    filterTag: function (filter) {
      var val = filter["isEnable"][0];
      this.getList();
    },
    //查询
    selSel() {
      this.pageCurrent4 = 1;
      this.getList();
    },
    // 新增
    selAdd() {
      this.dialogVisible = true;
      this.form = {};
    },
    //列表方法
    getList() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageSize = _this.pageSize;
      params.pageIndex = _this.currentPage4;
      var link = url.getSys("getlist_articleInfo");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    // 编辑
    editClick(row) {
      this.form = row;
      this.dialogVisible = true;
    },
    titleClick(row) {
      this.dia2.vis = true;
      this.dia2.title = row.title;
      row.viewCount += 1;
      $("#infoCard").empty();
      console.info(row.content);
      $("#infoCard").append(row.content);
      var link = url.getSys("addViewCount_articleInfo");
      var id = row.id;
      $.post(link, { id: id });

      //top.location.reload();
    },
    // 删除
    delClick(row) {
      var _this = this;
      var link = url.getSys("delete_articleInfo");
      var id = row.id;

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          $.post(link, { ids: id }, res => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //对话框方法
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("addOrUpdate_articleInfo");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({ type: "success", message: res.msg });
            } else {
              _this.$message({ type: "error", message: res.msg });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>